<template>
  <div class="d-flex flex-row flex-wrap">
    <div
      v-for="(v, idx) in dataValues"
      :key="idx + '_v'"
      class="d-flex flex-row mr-2 align-center"
    >
      <picker
        class="mt-4"
        :disabled="disabled"
        :rules="required"
        :clearable="false"
        :selected-id="dataValues[idx].id"
        :document-types="computedTargetDocTypes"
        @update:selected-id="setValue(idx, $event)"
        :attach="false"
        :label="label"
        :hide-details="false"
        :max-visible-suggestions=10
        :max-suggestions=30
        ref="input"
        :readonly="readonly"
      />
      <v-btn
        class="ml-2"
        v-if="dataValues.length != 1 && !readonly"
        icon
        small
        @click="removeComparand(idx)"
      >
        <v-icon small>{{$icon('i.Delete')}}</v-icon>
      </v-btn>
      <v-btn
        class="ml-2"
        v-if="idx === dataValues.length -1 && !readonly"
        icon
        small
        color="secondary"
        @click="addComparand"
      >
        <v-icon small>{{$icon('i.Plus')}}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import { CacheType } from '@/wasm/pkg'

export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedTargetDocTypes () {
      return this.valueTypeExtra?.document?.ty ?? this.valueTypeExtra?.ty ?? []
    },
    label () {
      return this.computedTargetDocTypes?.length ? this.$t(`t.${this.toPascalCase(this.computedTargetDocTypes)}`) : ''
    },
    valueType () {
      return this.targetValueType ? Object.keys(this.targetValueType)[0] : null
    },
    valueTypeExtra () {
      return this.valueType ? this.targetValueType[this.valueType] : null
    }
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      selectedComparands: [],
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataValues: [{}],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataValues)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataValues))
      }
    },
    addComparand () {
      this.dataValues.push({ id: null, type: this.computedTargetDocTypes })
      this.dataDocumentEmit()
    },
    setValue (idx, value) {
      this.dataValues[idx] = Object.assign({}, this.dataValues[idx], { id: value })
      this.dataDocumentEmit()
    },
    removeComparand (idx) {
      this.dataValues.splice(idx, 1)

      if (this.dataValues.length === 0) {
        this.dataValues = [{ id: null, type: this.computedTargetDocTypes }]
      }
      this.dataDocumentEmit()
    }
  },
  props: {
    targetValueType: [String, Object],
    document: Array,
    disabled: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataValues = v?.length ? this.lodash.cloneDeep(v) : [{ id: null, type: this.computedTargetDocTypes }]
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
